import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";

const buttonVariants = cva(
    "inline-flex items-center gap-x-2 justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default:
                    "bg-primary text-muted hover:!text-teal-600",
                theme:
                    "bg-teal-600 text-white hover:bg-background hover:border hover:border-teal-600 hover:text-teal-600",
                destructive:
                    "bg-destructive text-destructive-foreground hover:bg-teal-600",
                outline:
                    "border border-input bg-background hover:border-teal-600 hover:bg-accent text-primary hover:text-teal-600",
                secondary:
                    "bg-secondary text-secondary-foreground hover:bg-secondary/80",
                ghost: "hover:bg-accent hover:text-accent-foreground",
                link: "text-primary underline-offset-4 hover:underline",
            },
            size: {
                default: "h-10 px-4 py-2",
                xs: "h-7 rounded-md px-3 text-xs",
                sm: "h-9 rounded-md px-3",
                lg: "h-11 rounded-md px-8",
                xl: "h-14 rounded-md px-8",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "sm",
        },
    },
);

const Button = React.forwardRef(
    ({ className, variant, size, asChild = false, tip, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        if (!tip) {
            return (
                <Comp
                    className={cn(buttonVariants({ variant, size, className }))}
                    ref={ref}
                    {...props}
                />
            );
        }
        else {
            return (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Comp
                                className={cn(buttonVariants({ variant, size, className }))}
                                ref={ref}
                                {...props}
                            />
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>{tip}</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            )
        }
    },
);
Button.displayName = "Button";

export { Button, buttonVariants };
